import React, { FunctionComponent, ReactElement } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { FinancialPageImagesQuery } from '../shared/interfaces';

const FinancialPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<FinancialPageImagesQuery>(graphql`
        query {
            financialBackgroundImage: file(name: {eq: "financialBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            financialSummaryImage: file(name: {eq: "financialSummary"}, extension: {eq: "webp"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Financial"/>
            <GeneralJumbotron
                title="Financial"
                backgroundImageURL={queryResult?.financialBackgroundImage.publicURL}
                backgroundText="Financial"
            />
            <SummaryTemplate image={queryResult?.financialSummaryImage.publicURL}>
                <p>{`Would you like to settle your client’s entire care with a single entity?`}</p>
                <p>{`With a PCH IPA membership, that is exactly what you will be getting: one bill.`}</p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default FinancialPage;
